<template>
  <b-container class="col">
    <div class="row justify-content-center align-items-center">
      <img :src="require('@/assets/images/logo/delivery.png')" class="w-50" />
    </div>
    <div class="d-flex row">
      <p class="h1 w-100 font-medium-3 text-center">Coming Soon!</p>
    </div>
  </b-container>

  <!-- UNCOMMENT IN NEXT SPRINT   - KIT -->
  <!-- <b-card class="p-lg-1 mb-1">
    <div class="row justify-content-center align-items-center">
      <div
        class="col-3 my-1 mx-2 px-1 rounded box-shadow-1"
        v-for="(orderItem, index) in orderItems"
        :key="index"
      >
        <div class="row d-flex align-items-center py-1 px-1">
          <div
            class="col d-flex col-sm-12 col-md-12 col-lg-6 px-1 justify-content-center"
          >
            <div
              class="rounded box-shadow-1 py-1 px-2"
              :class="'bg-' + orderItem.color"
            >
              <feather-icon size="30" class="text-white" :icon=orderItem.icon />
            </div>
          </div>
          <div class="col justify-content-center px-0">
            <div class="row-9 font-large-2 text-center">
              {{ orderItem.value }}
            </div>
            <div class="row-3 font-small-2 text-center">
              {{ orderItem.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center align-items-center mt-5">
      <b-button variant="gradient-primary" class="box-shadow-1 px-3 mx-2" @click="dashboard">
        Back to Dashboard
      </b-button>
    </div>
  </b-card> -->
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BContainer,
  BLink,
  BCol,
  BRow,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BLink,
    BRow,
  },

  data() {
    return {
      orderItems: [
        {
          title: 'ORDER OF THE DAY',
          value: '100',
          icon: 'BoxIcon',
          color: 'info',
        },
        {
          title: 'ORDER IN COLD ROOM',
          value: '26',
          icon: 'CloudSnowIcon',
          color: 'success',
        },
        {
          title: 'ORDER IN TRANSIT',
          value: '55',
          icon: 'TruckIcon',
          color: 'warning',
        },

        {
          title: 'ORDER COMPLETED',
          value: '09',
          icon: 'CheckCircleIcon',
          color: 'warning',
        },
        {
          title: 'DHL ORDER',
          value: '34',
          icon: 'CompassIcon',
          color: 'secondary',
        },
        {
          title: 'THIS MONTH ORDER',
          value: '51',
          icon: 'CalendarIcon',
          color: 'success',
        },

        {
          title: 'ORDER BY WEEK',
          value: '165',
          icon: 'CalendarIcon',
          color: 'info',
        },
        {
          title: 'ORDER BY MONTH',
          value: '256',
          icon: 'PlusSquareIcon',
          color: 'success',
        },
        {
          title: 'OVERALL ORDER',
          value: '1,230',
          icon: 'BriefcaseIcon',
          color: 'warning',
        },
      ],
    };
  },

  methods: {
    dashboard() {
      this.$router.replace('/dashboard/quote-price');
    },
  },
};
</script>

<style></style>
